<template>
  <ui-component-modal
    :modalTitle="$t('Components.BlockRules.ModalCreateBlockRule.Main_Title')"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :disableSaveButton="!isSavingConfirmed"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="createBlockRule"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="form-options columns">
        <table class="table is-fullwidth is-striped">
          <tbody>
            <tr>
              <td width="100%">Type</td>
              <td>
                <div class="select">
                  <select @change="getItemData" v-model="mTypeId">
                    <option
                      v-for="typeId in typeIds"
                      :key="typeId"
                      :value="typeId"
                      :selected="mTypeId === typeId"
                      >{{ getTypeName(typeId) }}</option
                    >
                  </select>
                </div>
              </td>
            </tr>

            <tr v-if="mTypeId > -1">
              <td>Item</td>
              <td>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <div
                        class="select"
                        :class="{ 'is-loading': itemsLoading }"
                      >
                        <select
                          @change="itemSelected"
                          v-model="newBlockRule.ItemId"
                        >
                          <option
                            :value="0"
                            :selected="newBlockRule.ItemId === 0"
                            >{{
                              $t(
                                'Components.BlockRules.ModalCreateBlockRule.TableHeader_OptionPick'
                              )
                            }}</option
                          >
                          <option
                            v-for="item in items"
                            :key="item.Id"
                            :value="item.Id"
                            :selected="item.Id === mItemId"
                            >{{ item.Name }}</option
                          >
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr v-if="newBlockRule.TypeId > -1 && newBlockRule.ItemId > 0">
              <td>Name</td>
              <td>
                <ui-base-input-field
                  v-model="newBlockRule.Name"
                  :error="nameErrors"
                  :required="true"
                  :showLabel="false"
                  fieldType="text"
                  @input="$v.newBlockRule.Name.$touch()"
                  @blur="$v.newBlockRule.Name.$touch()"
                />
              </td>
            </tr>

            <tr v-if="newBlockRule.TypeId > -1 && newBlockRule.ItemId > 0">
              <td>Days</td>
              <td>
                <div v-for="day in days" :key="day">
                  <label class="checkbox">
                    <input
                      type="checkbox"
                      :value="day"
                      v-model="newBlockRule.Days"
                    />
                    <span class="has-margin-left">{{ day | getDayName }}</span>
                  </label>
                </div>
              </td>
            </tr>

            <tr v-if="newBlockRule.TypeId > -1 && newBlockRule.ItemId > 0">
              <td>Period</td>
              <td>
                <ui-base-date-field
                  :labelForInput="false"
                  v-model="newBlockRule.StartDate"
                  :openCalendarInModal="true"
                  @updateDates="
                    (value) => {
                      newBlockRule.StartDate = formatDates(value)
                    }
                  "
                />

                <ui-base-date-field
                  :labelForInput="false"
                  v-model="newBlockRule.EndDate"
                  :openCalendarInModal="true"
                  @updateDates="
                    (value) => {
                      newBlockRule.EndDate = formatDates(value)
                    }
                  "
                />
              </td>
            </tr>

            <tr v-if="newBlockRule.TypeId > -1 && newBlockRule.ItemId > 0">
              <td>Time slot</td>
              <td>
                <div class="select">
                  <select v-model="newBlockRule.StartMinutes">
                    <option
                      v-for="timeSlot in timeSlots"
                      :key="timeSlot"
                      :value="timeSlot"
                      :selected="newBlockRule.StartMinutes === timeSlot"
                      >{{ timeSlot | minutesToTime }}</option
                    >
                  </select>
                </div>
                -
                <div class="select">
                  <select v-model="newBlockRule.EndMinutes">
                    <option
                      v-for="timeSlot in timeSlots"
                      :key="timeSlot"
                      :value="timeSlot"
                      :selected="newBlockRule.EndMinutes === timeSlot"
                      >{{ timeSlot | minutesToTime }}</option
                    >
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import blockruleProvider from '@/providers/blockrule'
import optionProvider from '@/providers/option'
import spaceProvider from '@/providers/space'
import { required, minLength } from 'vuelidate/lib/validators'

const touchMap = new WeakMap()

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    typeId: {
      default: -1,
      type: Number,
    },

    itemId: {
      default: 0,
      type: Number,
    },

    meetingtypeId: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      days: [2, 3, 4, 5, 6, 7, 1],
      items: [],
      itemsLoading: false,
      locationId: Number(this.$route.params.locationId),
      mItemId: this.itemId,
      mTypeId: this.typeId,
      newBlockRule: null,
      timeSlots: [],
      ruleName: 'Block rule ',
      typeIds: [-1, 0, 1],
      isSaving: false,
      isSavingConfirmed: false,
      isSavingSuccess: false,
      isSavingError: false,
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      Name: {
        required,
        minLength: minLength(2),
      },
    }
    return {
      newBlockRule: output,
    }
  },

  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.newBlockRule.Name.$dirty) return errors
      !this.$v.newBlockRule.Name.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      !this.$v.newBlockRule.Name.minLength &&
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 2 }))
      return errors
    },
  },

  created() {
    this.resetNewBlockRule()
    this.fillTimeSlots()

    if (this.typeId > -1) {
      this.getItemData()
    }
    if (this.mItemId > 0) {
      this.itemSelected()
    }
  },

  methods: {
    getItemData() {
      let self = this
      self.itemsLoading = true
      self.items = []
      let typeId = self.mTypeId
      self.resetNewBlockRule(typeId)

      if (self.newBlockRule.TypeId === 0) {
        self.getLocationOptions()
      } else if (self.newBlockRule.TypeId === 1) {
        self.getLocationSpaces()
      }
    },

    getLocationOptions() {
      let self = this
      self.items = []

      optionProvider.methods
        .getLocationOptions(self.locationId)
        .then((response) => {
          if (response.status === 200) {
            for (let i = 0; i < response.data.Results.length; i++) {
              let item = response.data.Results[i]
              this.items.push({
                Id: item.Id,
                Name: item.Name,
              })
            }
          }
        })
        .finally(() => {
          self.itemsLoading = false
        })
    },

    getLocationSpaces() {
      let self = this
      self.items = []

      spaceProvider.methods
        .getLocationSpaces(self.locationId, '', 0, 0, 0, 100)
        .then((response) => {
          if (response.status === 200) {
            for (let i = 0; i < response.data.Results.length; i++) {
              let item = response.data.Results[i]
              this.items.push({
                Id: item.Id,
                Name: item.Name,
              })
            }
          }
        })
        .finally(() => {
          self.itemsLoading = false
        })
    },

    getTypeName(typeId) {
      let name = ''

      switch (typeId) {
        case -1:
          name = 'Select type'
          break
        case 0:
          name = 'Option'
          break
        case 1:
          name = 'Space'
          break
      }

      return name
    },

    getMeetingtypeName(meetingtypeId) {
      let name = ''

      switch (meetingtypeId) {
        case 0:
          name = 'All meeting types'
          break
        case 1:
          name = 'Meetingspace'
          break
        case 2:
          name = 'Workspace'
          break
        case 3:
          name = 'Deskpace'
          break
      }

      return name
    },

    itemSelected() {
      this.newBlockRule.ItemId > 0
        ? (this.isSavingConfirmed = true)
        : (this.isSavingConfirmed = false)

      let itemIndex = this.items.findIndex(
        (i) => i.Id === this.newBlockRule.ItemId
      )
      if (itemIndex > -1) {
        let item = this.items[itemIndex]
        this.newBlockRule.ItemName = item.Name
        this.newBlockRule.Name = this.ruleName + item.Name
      }
    },

    fillTimeSlots() {
      let min = 0
      let max = 1440
      let minutes = 30

      let i = min
      while (i <= max) {
        this.timeSlots.push(i)
        i = i + minutes
      }
    },

    createBlockRule() {
      let self = this

      this.$v.$touch()
      if (this.$v.$invalid) {
        // 'ERROR'
        self.isSaving = false
      } else {
        if (!self.isSaving) {
          self.isSaving = true
          this.isSavingSuccess = false
        }
        blockruleProvider.methods
          .createBlockRule(self.newBlockRule)
          .then((response) => {
            if (response.status === 201) {
              setTimeout(() => {
                self.onClickCancel()
              }, 1500)

              self.$emit('newRuleCreated', response.data)
              self.resetNewBlockRule()
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            this.isSaving = false
            this.isSavingSuccess = true
          })
      }
    },

    formatDates(date) {
      const offset = date.getTimezoneOffset()
      date = new Date(date.getTime() - offset * 60 * 1000)
      return date.toISOString().split('T')[0]
    },

    resetNewBlockRule() {
      this.newBlockRule = {
        Id: 0,
        LocationId: this.locationId,
        MeetingtypeId: this.meetingtypeId,
        TypeId: this.mTypeId,
        ItemId: this.mItemId,
        ItemName: '',
        Name: this.ruleName + this.getMeetingtypeName(this.mMeetingtypeId),
        StartDate: new Date(),
        EndDate: new Date(),
        Days: [2, 3, 4, 5, 6, 7, 1],
        StartMinutes: 0,
        EndMinutes: 1440,
      }
    },
  },
}
</script>
